//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import langs from "@/extras/langs";
import { mapState } from "vuex";
import VueCropper from "vue-cropperjs";
import MovieImage from "@/components/movie/Image";
import "cropperjs/dist/cropper.css";
import { submission_service, profile_service } from "@/services";
import { GENRE_REQUEST } from "@/store/actions";
export default {
  props: {
    initData: {
      type: Object,
      default() {
        return {};
      },
    },
    triggerSubmit: {
      type: Number,
      default: 0,
    },
  },
  components: {
    VueCropper,
    MovieImage,
  },
  data() {
    return {
      selected_movie_id: null,
      submitted_movies: [],
      cropping_in_progress: false,
      loading: false,
      error_msg: "",
      languages: Object.values(langs),
      filtered_lang: [],
      submit_error: {
        title: "",
        link: "",
        lang: "",
        runtime: "",
        poster: "",
        roles: "",
        genres: "",
        director: {
          name: "",
          email: "",
          contact: "",
        },
      },
      original_poster: undefined,
      poster: undefined,
      poster_crop_dialog: false,
      submit_data: {
        title: "",
        link: "",
        lang: undefined,
        runtime: "",
        poster: "",
        roles: [],
        genres: [],
        director: {
          name: "",
          email: "",
          contact: "91",
        },
        extras: {
          college: "",
        }
      },
    };
  },
  computed: {
    ...mapState({
      roles: (state) => state.role.roles,
      genres: (state) => state.genre.genres,
    }),
    show_director_fields() {
      var show = true;
      this.submit_data.roles.forEach((role) => {
        if (role.name === "Director") show = false;
      });
      return show;
    },
    poster_image_url() {
      if (this.original_poster)
        return URL.createObjectURL(this.original_poster);
      return this.original_poster;
    },
    selected_movie() {
      var selected_movie = {};
      this.submitted_movies.forEach((movie) => {
        if (movie.id === this.selected_movie_id) selected_movie = movie;
      });
      return selected_movie;
    },
  },
  watch: {
    genres() {
      var new_genres = [];
      this.submit_data.genres.forEach((g) => {
        this.genres.forEach((genre) => {
          if (g.id === genre.id) {
            new_genres.push(genre);
          }
        });
      });
      this.submit_data.genres.splice(0, this.submit_data.genres.length);
      this.submit_data.genres.push(...new_genres);
    },
    triggerSubmit() {
      // called when attempt_submit is changed in parent to trigger submittion attempt here
      if (this.selected_movie_id) {
        this.submit_data.selected_movie_id = this.selected_movie_id;
        this.$emit(
          "complete",
          Object.assign(
            {
              id: this.selected_movie_id,
              original_poster: this.original_poster,
            },
            this.submit_data
          ),
          this.selected_movie
        );
      } else {
        this.on_submit();
      }
    },
    poster_crop_dialog(value) {
      if (!value && !this.submit_data.poster) {
        this.original_poster = undefined;
      }
    },
    original_poster(value) {
      if (value && value !== this.initData.original_poster) {
        this.submit_data.poster = undefined;
        this.poster_crop_dialog = true;
      }
    },
    loading() {
      this.$emit("loading", this.loading);
    },
  },
  mounted() {
    this.get_submissions();
    this.$store.dispatch(GENRE_REQUEST);
    this.filtered_lang = this.languages;
    this.selected_movie_id = this.initData.selected_movie_id;
    delete this.initData["selected_movie_id"];
    Object.assign(this.submit_data, this.initData);
    this.original_poster = this.initData.original_poster;
  },
  methods: {
    get_submissions() {
      profile_service
        .get({}, `${this.my_profile.id}/submissions`)
        .then((data) => {
          data.results.forEach((sub) => {
            if (sub.id !== this.submit_data.id) this.submitted_movies.push(sub);
          });
        })
        .catch((error) => {
          this.error_msg = this.decode_error_message(error);
        });
    },
    map_error_fields(error) {
      var has_errors1 = false;
      var has_errors2 = false;
      if (error.response && error.response.data) {
        if (error.response.data.payload)
          has_errors1 = this.check_fields_for_error(
            error.response.data.payload,
            this.submit_error,
            ["poster", "title", "link", "runtime", "genres"]
          );
        if (error.response.data.payload.director) {
          this.submit_error.director = {};
          has_errors2 = this.check_fields_for_error(
            error.response.data.payload.director,
            this.submit_error.director,
            ["name", "email", "contact"]
          );
        }
      }
      return has_errors1 || has_errors2;
    },
    build_director_data() {
      var director = JSON.parse(JSON.stringify(this.submit_data.director));
      // if only country prefix was there
      director.contact =
        director.contact.length == 2 ? undefined : director.contact;
      if (director.name || director.email) {
        var name = director.name;
        if (name) {
          var name_segs = name.split(/[\s,]+/);
          director.last_name = "";
          if (name_segs.length > 0) director.first_name = name_segs.shift();
          if (name_segs.length > 0) director.last_name = name_segs.join(" ");
          delete director["name"];
        }
        return director;
      }
    },
    build_form_data() {
      //  copy the submit_data
      const data = JSON.parse(JSON.stringify(this.submit_data));
      delete data["poster"];

      const form_data = new FormData();
      if (this.submit_data.poster)
        form_data.append("poster", this.submit_data.poster, "poster.png");

      data.director = this.build_director_data(data.director);
      // undefined values are ommited by JSON.stringify, hence director key will be removed in value is undefined
      form_data.append("payload", JSON.stringify(data));
      return form_data;
    },
    clear_errors() {
      this.submit_error = {
        title: "",
        link: "",
        lang: "",
        runtime: "",
        poster: "",
        roles: "",
        genres: "",
        director: {
          name: "",
          email: "",
          contact: "",
        },
      };
      this.error_msg = "";
    },
    poster_rejected() {
      this.submit_error.poster = "Only image files are supported";
    },
    crop_poster() {
      this.cropping_in_progress = true;
      this.$refs.cropper
        .getCroppedCanvas({
          width: 1824,
          height: 1026,
          fillColor: "#fff",
        })
        .toBlob((blob) => {
          this.cropping_in_progress = false;
          this.submit_data.poster = blob;
          this.poster_crop_dialog = false;
        }, "image/png");
    },
    lang_filter_fn(val, update) {
      update(() => {
        if (val === "") {
          this.filtered_lang = this.languages;
        } else {
          const needle = val.toLowerCase();
          this.filtered_lang = this.languages.filter(
            (v) => v.name.toLowerCase().indexOf(needle) > -1
          );
        }
      });
    },
    on_submit() {
      this.clear_errors();
      this.$refs.submit.validate().then((valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;

        var form_data = this.build_form_data();
        // if the movie was earlier submitted, perform an PATCH call otherwwise do a POST
        var action = this.initData.id ? "patch" : "post";
        var movie_id = this.initData.id ? this.initData.id : "";
        // movie_id will be ignored if its a POST
        submission_service[action](form_data, movie_id)
          .then((res_data) => {
            this.loading = false;
            this.$emit(
              "complete",
              Object.assign(
                { id: res_data.id, original_poster: this.original_poster },
                this.submit_data
              ),
              res_data
            );
          })
          .catch((err) => {
            this.loading = false;
            var found_field_error = this.map_error_fields(err);
            if (found_field_error) {
              this.error_msg = "Please fix the error(s) above to continue";
            } else
              this.error_msg =
                this.decode_error_message(err) || "There was an error !";
          });
      });
    },
  },
};

import { render, staticRenderFns } from "./SubmitForm.vue?vue&type=template&id=2f4810ab&scoped=true&"
import script from "./SubmitForm.vue?vue&type=script&lang=js&"
export * from "./SubmitForm.vue?vue&type=script&lang=js&"
import style0 from "./SubmitForm.vue?vue&type=style&index=0&id=2f4810ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4810ab",
  null
  
)

export default component.exports
import {QImg,QForm,QInput,QSelect,QFile,QField,QCheckbox,QDialog,QCard,QCardSection,QCardActions,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg,QForm,QInput,QSelect,QFile,QField,QCheckbox,QDialog,QCard,QCardSection,QCardActions,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})

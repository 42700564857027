//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-account-group",
          color: "light-green",
          text: "Get your film screened at IIM Rohtak.",
        },
        // {
        //   icon: "mdi-youtube",
        //   color: "red-8",
        //   text: "Build a consistent audience for your YouTube releases. We drive a traffic of over 160,000 cinephiles to your content.",
        // },
        {
          icon: "mdi-currency-usd",
          color: "blue",
          text: "You stand a chance to win cash rewards worth Rs.40,000.",
        },
        {
          icon: "mdi-trophy",
          color: "amber",
          text: "Your film stands a chance to get recommended by our jury of judges from the industry.",
        },
        {
          icon: "mdi-bullseye-arrow",
          color: "deep-purple",
          text: "We curate your profile as a filmmaker and connect you with the audience.",
        },
        // {
        //   icon: "mdi-check-decagram",
        //   color: "light-blue",
        //   text: "We simplify the fundraising for your projects. We connect you with film enthusiasts to get your projects funded.",
        // },
      ],
    };
  },
};
